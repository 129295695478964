import React from 'react';

import {
    Button,
    Image,
} from './elements';

import FacebookIcon from './facebook.svg';
import InstagramIcon from './instagram.svg';
import LinkedInIcon from './linkedin.svg';



const SocialButton = ({social, href = '', visible = true}) => {
    let imageSocial = null;

    if ( href === '' || !href ) {
        // In case of no valid href return nothing
        return <noscript />;
    }
    
    switch (social) {
        case 'facebook':
            imageSocial = FacebookIcon;
            break;
        case 'linkedin':
            imageSocial = LinkedInIcon;
            break;
        default:
            imageSocial = InstagramIcon;
    }
    return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={{
              visibility: !visible ? 'hidden' : '',
          }}
        >
            <Button>
                <Image src={imageSocial} />
            </Button>
        </a>
    );
};

export default SocialButton;
