import styled from 'styled-components';


export const FooterWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    @media (max-width: 600px) {
        padding: 0 17px;
        padding: ${props => props.nopadding && '0'};
        flex-direction: row-reverse;
    }
`;

export const Bar = styled.div`
    border: 1px solid #0064BA;
    display: block;
    min-height: 1px;
    width: 100%;
    margin-bottom: 57px;
    @media (max-width: 600px) {
        margin-bottom: 35px;
    }
`;

export const IconWrapper = styled.div`
    padding: 0;
    display: flex;
    @media (max-width: 600px) {
        margin-top: 20px;
    }
`;

export const FooterText = styled.a`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 55px;
    color: #0064BA;
    margin-right: 20px;
    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
    }
`;

export const MobileVisible = styled.div`
    width: 100%;
    display: none;
    @media (max-width: 600px) {
        display: block;
        margin-bottom: 10px;
    }
`;

export const DesktopVisible = styled.div`
    width: 100%;
    display: inline;
    @media (max-width: 600px) {
        display: none;
    }
`;


export default FooterWrapper;
