import React from 'react';

export const AppearText = ({children}) => {
    return <div style={{ overflow: 'hidden', paddingLeft: 1, paddingRight: 1, paddingBottom: 2, }}>
        <div data-scroll className="animation-on-viewport" >
            {children}
        </div>
    </div>
}

export const AppearImage = ({ children }) => {
    return <div data-scroll className="animation-on-viewport-image" >
            {children}
        </div>
}


export function withAppear(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = { visible: false };
            this.handleEnter = this._handleEnter.bind(this);
        }

        _handleEnter() {
            this.setState({ visible: true })
        }

        componentDidMount() {
            window.addEventListener('end-loading', this.handleEnter);
            window.addEventListener('end-transition', this.handleEnter);
        }

        componentWillUnmount() {
            window.removeEventListener('end-loading', this.handleEnter);
            window.removeEventListener('end-transition', this.handleEnter);
        }

        render() {
            const { visible } = this.state;
            if ( !visible ) {
                return (<div style={{ visibility: 'hidden', overflow: 'hidden' }}>
                    <WrappedComponent {...this.props} />
                </div>);
            }
            return <WrappedComponent {...this.props} />;
        }
    }
}



export default {
    withAppear,
    AppearImage,
    AppearText,
};