import styled from 'styled-components';


export const Button = styled.div`
    margin-right: 20px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    
    position: relative;
    transition: all 1s ease-in-out;

    background-color: #DCECF9;
    @media (max-width: 1200px) {
        background-color: #0064BA;
    }
    &:hover {
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        background-color: #0064BA;
    }
`;

export const Image = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    height: 26px;
    width: auto;
    transform: translate(-50%, -50%);
    pointer-events: none;
`;

export default {
    Button,
    Image,
}