import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import {
    FooterWrapper,
    FooterText,
    Bar,
    IconWrapper,
    MobileVisible,
    DesktopVisible
} from './elements';
import { AppearImage, } from '../appear';
import SocialIcon from '../../socialButton';

const Footer = ({ data, variant = 1, noPadding = false}) => (
    <FooterWrapper nopadding={noPadding}>
    {variant === 1 ? <AppearImage><Bar /></AppearImage> : <noscript /> }
        <MobileVisible>
          <FooterText>
            © 2020 Blue Ocean Group. All Rights Reserved.
                </FooterText>
          <FooterText
            href="https://www.gbrdesign.com/"
            target="_blank"
          >
            Credits
          </FooterText>
        </MobileVisible>
        <IconWrapper data-scroll className="animation-on-viewport-image">
            {
              data.allMarkdownRemark && data.allMarkdownRemark.edges.reverse().map((element, index) => {
                return <SocialIcon
                  social={element.node.frontmatter.type}
                  href={element.node.frontmatter.url}
                />
              })
            }
            
            <DesktopVisible data-scroll className="animation-on-viewport-image">
                  <FooterText>
                      © 2020 Blue Ocean Group. All Rights Reserved.
                  </FooterText>
                  <FooterText
                    href="https://www.gbrdesign.com/"
                    target="_blank"
                  >
                      Credits
                  </FooterText>
            </DesktopVisible>
        </IconWrapper>
    </FooterWrapper>
);

export default function myFooter(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "socials-items"}}}) {
            edges {
              node {
                frontmatter {
                  type
                  url
                }
              }
            }
          }
        }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}